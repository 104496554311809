<template>
  <div class="app-container">
    <el-tabs type="border-card" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="财务统计表" name="财务统计表"></el-tab-pane>
      <el-tab-pane label="学生财务统计表" name="学生财务统计表" v-if="this.userRoleName != '学生'"></el-tab-pane>
      <div class="content">
        <div class="content_data">
          <div
            style="display: flex; align-items: center;width:98%;margin: 0 auto;height:70px"
          >
            <div style="width:20%">统计</div>
            <div class="each">
              <div class="icon_bg">
                <i class="iconfont icon-gongsi1"></i>
              </div>
              <div
                style="margin-left:5px;cursor: pointer;"
                @click="getType(0)"
              >
                <div style="margin-bottom:5px">企业总数</div>
                <a>
                  <span>{{ comTotal }}</span
                  >家
                </a>
              </div>
            </div>
            <div class="each">
              <div class="icon_bg">
                <i class="iconfont icon-zichan"></i>
              </div>
              <div
                style="margin-left:5px;cursor: pointer;"
                @click="getType(1)"
              >
                <div style="margin-bottom:5px">有收入</div>
                <a>
                  <span>{{ comYsr }}</span
                  >家
                </a>
              </div>
            </div>
            <div class="each">
              <div class="icon_bg">
                <i class="iconfont icon-jiezhang"></i>
              </div>
              <div
                style="margin-left:5px;cursor: pointer;"
                @click="getType(2)"
              >
                <div style="margin-bottom:5px">有盈利</div>
                <a>
                  <span>{{ comYyl }}</span
                  >家
                </a>
              </div>
            </div>
            <div class="each">
              <div class="icon_bg">
                <i class="iconfont icon-jizhanghesuan"></i>
              </div>
              <div
                style="margin-left:5px;cursor: pointer;"
                @click="getType(3)"
              >
                <div style="margin-bottom:5px">有亏损</div>
                <a>
                  <span>{{ comYks }}</span
                  >家
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="content_select">
          <div>
            账期：
            <qzf-search-period
              v-model:period="listQuery.beginPeriod"
              @success="getList"
            ></qzf-search-period>
            <span style="margin:0 3px">至</span>
            <qzf-search-period
              v-model:period="listQuery.endPeriod"
              @success="getList"
            ></qzf-search-period>
            <el-input
              placeholder="请输入企业名称"
              v-model="listQuery.name"
              style="width: 160px;margin: 0 5px"
              size="small"
              @keyup.enter="getList"
            />
            <el-button type="primary" @click="getList" size="small" style="margin-right:10px">搜索</el-button>
            <search  @success="getList" v-if="this.userRoleName != '学生' && activeName == '学生财务统计表'">
              <el-form style="margin:11px 0px 0px 15px;width:400px" class="styleForm">
                <el-form-item label="选择班级 ：" :label-width="formLabelWidth" >   
                  <selectClass v-model:deptId="listQuery.deptId" @success="getUserList"></selectClass>
                </el-form-item>
                <el-form-item label="选择人员：" :label-width="formLabelWidth">
                  <selectuser ref="selectuser" code="bookkeeping" eduCode="" v-model:userId="listQuery.userIds" @success="getUser"></selectuser>
                </el-form-item>
              </el-form>
            </search>
          </div>
          <div>
            <el-button type="primary" size="small" @click="reFresh"
              >更新数据</el-button
            >
          </div>
        </div>
        <div>
          <el-table
            :data="tableData"
            border
            style="width: 100%"
            v-loading="loading"
            :height="contentStyleObj"
          >
            <template #empty>
              <el-empty :image-size="150" description="没有数据"></el-empty>
            </template>
            <el-table-column prop="name" label="企业名称" min-width="150">
              <template #default="scope">
                <span v-if="scope.row.type == '1'" class="xgmStyle">小</span>
                <span v-if="scope.row.type == '2'" class="ybStyle">般</span>
                <span
                  @click="$goEtax(scope.row.id)"
                  style="cursor:pointer"
                  type="text"
                  >{{ scope.row.name }}</span
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="startAccountPeriod"
              label="启用账期"
              align="right"
            />
            <el-table-column
              prop="yysr"
              label="营业收入"
              sortable
              align="right"
            />
            <el-table-column
              prop="yycb"
              label="营业成本"
              sortable
              align="right"
            />
            <el-table-column
              prop="qjfy"
              label="期间费用"
              sortable
              align="right"
            />
            <el-table-column
              prop="lrze"
              label="利润总额"
              sortable
              align="right"
            />
            <el-table-column
              prop="ytfy"
              label="预提费用"
              sortable
              align="right"
            />
            <el-table-column
              prop="wlzg"
              label="往来暂估"
              sortable
              align="right"
            />
          </el-table>
        </div>
        <div class="pagination">
          <qzf-pagination
            v-show="total > 0"
            :total="total"
            v-model:page="listQuery.page"
            v-model:limit="listQuery.limit"
            @pagination="getList()"
          />
        </div>
      </div>
    </el-tabs>
  </div>
</template>

<script>
import { currentAccountPeriod } from "@/utils";
import { cwtjList, cwtjFresh } from "@/api/company";
export default {
  name: "cwtj",
  components: {},
  props: {},
  data() {
    return {
      listQuery: {
        page: 1,
        limit: 20,
        name: "",
        beginPeriod: currentAccountPeriod(),
        endPeriod: currentAccountPeriod(),
        type: 0,
        userIds:[this.$store.getters['user/user'].userId]
      },
      loading: false,
      tableData: [],
      total: 0,
      comTotal: 0,
      comYks: 0,
      comYsr: 0,
      comYyl: 0,
      contentStyleObj:{},
      activeName:'财务统计表',
      userRoleName:this.$store.getters['user/user'].userRoleName

    };
  },

  created() {
    this.contentStyleObj= this.$getHeight(370)
    this.getList();
  },
  methods: {
    getList() {
      this.loading = true;
      cwtjList(this.listQuery).then((res) => {
        this.loading = false;
        if (res.data.msg == "success") {
          this.tableData = res.data.data.list;
          this.total = res.data.data.total;
          this.comTotal = res.data.data.comTotal;
          this.comYks = res.data.data.comYks;
          this.comYsr = res.data.data.comYsr;
          this.comYyl = res.data.data.comYyl;
        }
      });
    },
    handleClick(tab) {
      this.listQuery.userIds = []
      if(tab.props.label == "财务统计表") {
        this.listQuery.userIds = [this.$store.getters['user/user'].userId]
        this.getList()
      }else if(tab.props.label == "学生财务统计表") {
        this.getList()
      }
    },
    //筛选
    getType(flag) {
      this.listQuery.type = flag;
      this.getList();
    },
    reFresh() {
      this.loading = true;
      cwtjFresh({}).then((res) => {
        this.loading = false;
        this.$qzfMessage(res.data.data);
      });
    },
    getUserList(){
      this.$refs.selectuser.init(this.listQuery.deptId)
    },
    getUser(e){
      this.listQuery.userIds = []
      if(this.listQuery.userIds.length == 0){
        e.map(v => {
          this.listQuery.userIds.push(v.id)
        })
      } 
    }
  },
};
</script>

<style scoped lang="scss">
.main {
  width: 98%;
  margin: 0 auto;
  padding-top: 20px;
}
.content {
  width: 98%;
  margin: 0 auto;
}
.content_data {
  background-color: #d8ecff;
}
.each {
  width: 15%;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #333;
}
.each span {
  font-size: 16px;
  font-weight: 600;
  color: #000;
}
.each a {
  text-decoration-line: underline;
  text-decoration-color: #141720;
}
.icon_bg {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #aedcfb;
  text-align: center;
  line-height: 35px;
}
.iconfont {
  font-size: 18px;
  color: #3c6ff2;
}
.content_select {
  margin: 15px 0;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
